import * as Sentry from '@sentry/browser';
interface SiteL2Type {
  [key: string]: string;
}

const videoAdRequest = () => {
  const cncPageDefinition = window.__cncPageDefinition || {};
  // advertising types preroll_{count}; midroll_2, postroll_1
  let adType: string;

  // supported ad sizes
  const adSizes = ['1920x1080', '1280x720', '1024x576', '768x576', '640x480', '640x360', ''];
  // url advertising system
  const baseUrl = 'https://pubads.g.doubleclick.net/gampad/ads';

  const adUnitKey = '/21869710533';
  const adUnitArea = 'ostatni';
  const adUnitTemplate = 'bigplayer_play';

  const siteL2: SiteL2Type = {
    abicko: 'cnc260',
    ahaonline: 'cnc260',
    auto: 'cnc260',
    autorevue: 'cnc260',
    blesk: 'cnc260',
    bleskprozeny: 'cnc260',
    dama: 'cnc260',
    e15: 'cnc260',
    eleague: 'cnr260',
    evropa2: 'cnr260',
    extra: 'cnr260',
    extralife: 'cnr260',
    extrastory: 'cnr260',
    frekvence1: 'cnr260',
    gcz: 'cnr260',
    hledejceny: 'cnc260',
    hokej: 'cnr260',
    info: 'cnr260',
    isport: 'cnc260',
    lideazeme: 'cnc260',
    lifee: 'cnr260',
    malltv: 'cnr260',
    maminka: 'cnc260',
    mimibazarcz: 'cnc260',
    mimibazarsk: 'cnc260',
    mobilmania: 'cnc260',
    mojezdravi: 'cnc260',
    mojepsychologie: 'cnc260',
    nhl: 'cnr260',
    onetv: 'cnr260',
    recepty: 'cnc260',
    reflex: 'cnc260',
    sparta: 'cnr260',
    sportrevue: 'cnc260',
    toprecepty: 'cnr260',
    youradio: 'cnr260',
    zeny: 'cnc260',
    zive: 'cnc260',
  };

  const getValuesPageDefinition = (key: string): string => {
    if (!(key in cncPageDefinition)) {
      return '';
    }

    const value = cncPageDefinition[key];
    if (typeof value === 'string') {
      return value;
    }

    return value.toString();
  };

  const getUnit = () => {
    let site = getValuesPageDefinition('site');

    if (site) {
      site = site.replace(/dev_/g, '');
      const l2 = siteL2[site] || '';

      return `${adUnitKey}/${l2}/${site}/${adType}/${adUnitTemplate}/${adUnitArea}`;
    }

    return '';
  };

  const getQueryParams = (): string => {
    // query params do url
    const queryParams = {
      env: 'vp',
      gdfp_req: '1',
      impl: 's',
      iu: getUnit(),
      npa: '0',
      output: 'vast',
      sz: encodeURIComponent(adSizes.join('|')),
      tfcd: '0',
      unviewed_position_start: '1',
      plcmt: '2',
    };

    const arr = Object.entries(queryParams).map((entry) => {
      return `${entry[0]}=${entry[1]}`;
    });

    return arr.join('&');
  };

  const getBisko = (): string => {
    try {
      const cxSegments = (localStorage.cxSegments || '') as string;
      const result = cxSegments;

      return result;
    } catch (e) {
      Sentry.captureException(e);
      return '';
    }
  };

  const getUrlTargeting = () => {
    return window.location.pathname.split('/').toString();
  };

  const getCustomParams = (): string => {
    const site = getValuesPageDefinition('site').replace(/dev_/g, '');

    const customParams = {
      L2: siteL2[site] || null,
      URL: getUrlTargeting(),
      area: 'ostatni',
      bisko: getBisko(),
      keywords: getValuesPageDefinition('keywords'),
      safety: getValuesPageDefinition('safety'),
      site,
      template: adUnitTemplate,
      target_position: adType,
    };

    const customParamsArray = Object.entries(customParams).map((entry) => {
      return `${entry[0]}=${entry[1] as string}`;
    });

    let result = `cust_params=${encodeURIComponent(customParamsArray.join('&'))}`;

    if (adType === 'preroll_{count}') {
      // must remain unencoded { and } in the preroll so that the codex can replace
      // preroll_{count} with preroll_1 according to the order of the calls
      const reg1 = /(%7B)/;
      const reg2 = /(%7D)/;

      result = result.replace(reg1, '{');
      result = result.replace(reg2, '}');
    }

    return result;
  };

  const setAdType = (adTypeName: 'preroll_{count}' | 'midroll_1' | 'postroll_1'): string => {
    adType = adTypeName;

    return adType;
  };

  /**
   * url for preroll
   * @returns {string}
   */
  const getPreRollUrl = (): string => {
    setAdType('preroll_{count}');

    return `${baseUrl}?${getQueryParams()}&${getCustomParams()}`;
  };

  /**
   * url for midroll
   * @returns {string}
   */
  const getMidRollUrl = (): string => {
    setAdType('midroll_1');

    return `${baseUrl}?${getQueryParams()}&${getCustomParams()}`;
  };

  /**
   * url for postroll
   * @returns {string}
   */
  const getPostRollUrl = (): string => {
    setAdType('postroll_1');

    return `${baseUrl}?${getQueryParams()}&${getCustomParams()}`;
  };

  return {
    getBisko,
    getUrlTargeting,
    getValuesPageDefinition,
    getUnit,
    setAdType,
    getCustomParams,
    getMidRollUrl,
    getPostRollUrl,
    getPreRollUrl,
    getQueryParams,
  };
};

export default videoAdRequest;

export interface VideoAdRequestType {
  getBisko: () => string;
  getUrlTargeting: () => string;
  getValuesPageDefinition: (key: string) => string;
  getUnit: () => string;
  setAdType: (adTypeName: 'preroll_{count}' | 'midroll_1' | 'postroll_1') => string;
  getCustomParams: () => string;
  getMidRollUrl: () => string;
  getPostRollUrl: () => string;
  getPreRollUrl: () => string;
  getQueryParams: () => string;
}
