import * as Sentry from '@sentry/browser';

interface AddScriptToDOMInterface {
  src: string;
  defer?: boolean;
}

const addScriptToDOM = async ({ src, defer = true }: AddScriptToDOMInterface) => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      if (defer) {
        script.defer = true;
      }
      document.body.appendChild(script);

      script.onload = () => {
        resolve('ok');
      };
    } catch (err) {
      reject(err);
      Sentry.captureException(err);
    }
  });
};

export default addScriptToDOM;
