const getBrowserName = (): string => {
  const { userAgent } = window.navigator;

  let browserName;

  if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/opr\//i) || userAgent.match(/OPR\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/MSIE/i) || userAgent.match(/Trident/i)) {
    browserName = 'IE';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};

export default getBrowserName;
