import getBrowserName from '../../utils/browserName';

export interface GemiusType {
  gemiusIdentifier: string;
  playerId: string;
  templateLocation: string;
  typology: string;
}

export interface HitType {
  time: number;
  type: string;
}

export interface NewProgramType {
  duration: number;
  quality?: string;
  title: string;
  videoId: string;
  videoMagazineName: string;
  autoplay: boolean;
  programProducer?: string;
}

export interface NewAdType {
  adId: string;
  duration: number;
  position: string;
  title?: string;
}

export interface GemiusServiceType {
  newAd: (props: NewAdType) => Promise<void>;
  newProgram: (props: NewProgramType) => Promise<void>;
  sendAdHit: (props: HitType) => Promise<void>;
  sendHit: (props: HitType) => Promise<void>;
}

interface AdditionalParametersType {
  customAttribute1?: string;
  customAttribute2?: string;
  customAttribute3?: string;
  customAttribute4?: string;
  programDuration: number;
  programName: string;
  programProducer?: string;
  programType: string;
  typology: string;
  videoMagazineName?: string;
}

interface AdditionalParametersAdType {
  adDuration?: number;
  adName?: string;
  adType: string;
  campaignClassification?: string;
  quality?: string;
  resolution?: string;
  volume?: string;
  customAttribute1?: string;
  customAttribute2?: string;
  customAttribute3?: string;
  customAttribute4?: string;
}

export interface GemiusPlayerType {
  newProgram: (videoId: string, additionalParameters: AdditionalParametersType) => Promise<void>;
  programEvent: (videoId: string, time: number, eventType: string) => Promise<void>;
  newAd: (adId: string, additionalParameters?: AdditionalParametersAdType) => Promise<void>;
  adEvent: (
    programId: string,
    adId: string | undefined,
    offset: number,
    eventType: string,
    additionalParameters: {
      autoPlay?: boolean;
      adPosition?: number;
      breakSize?: number;
      resulution?: number;
      volume?: number;
    }
  ) => Promise<void>;
}

interface GemiusPlayerAditionalParametersType {
  currentDomain: string;
}

interface GemiusPlayerConstructor {
  new (
    playerId: string,
    gemiusIdentifier: string,
    { currentDomain }: GemiusPlayerAditionalParametersType
  ): GemiusPlayerType;
}

declare let GemiusPlayer: GemiusPlayerConstructor;

const gemius = ({
  playerId,
  templateLocation,
  gemiusIdentifier,
  typology,
}: GemiusType): GemiusServiceType => {
  if (typeof GemiusPlayer === 'undefined') {
    return {
      newAd: () => Promise.resolve(),
      newProgram: () => Promise.resolve(),
      sendAdHit: () => Promise.resolve(),
      sendHit: () => Promise.resolve(),
    };
  }

  const gPlayer = new GemiusPlayer(playerId, gemiusIdentifier, {
    currentDomain: window.location.hostname,
  });
  let currVideoId: string;
  let currAdId: string;
  const browserName = getBrowserName();

  const getCustomAttr1 = (location?: string, autoPlay: boolean = false): string => {
    return `${location === 'mini' ? 'Mini player' : 'Normal'}${autoPlay ? ' autoplay' : ''}`;
  };

  const newProgram = async ({
    videoId,
    title,
    duration,
    videoMagazineName,
    autoplay,
    programProducer,
  }: NewProgramType) => {
    currVideoId = videoId;

    const dataObj: AdditionalParametersType = {
      customAttribute1: getCustomAttr1(templateLocation, autoplay),
      customAttribute2: videoMagazineName,
      customAttribute3: browserName,
      customAttribute4: 'VP player',
      programDuration: Math.round(duration),
      programName: title.replace(/(['„“†`])/g, ''),
      programType: 'Video',
      typology,
    };

    if (programProducer !== undefined && programProducer !== '') {
      dataObj.programProducer = programProducer;
    }

    await Promise.resolve(gPlayer.newProgram(currVideoId, dataObj));
  };

  const sendHit = async ({ type, time = 0 }: HitType) => {
    await Promise.resolve(gPlayer.programEvent(currVideoId, Math.round(time), type));
  };

  const newAd = async ({ adId, duration, position, title }: NewAdType) => {
    currAdId = adId;

    await Promise.resolve(
      gPlayer.newAd(adId, {
        adDuration: Math.round(duration),
        adName: title,
        adType: position,
      })
    );
  };

  const sendAdHit = async ({ time, type }: HitType) => {
    if (typeof currAdId === 'undefined') {
      await Promise.resolve(gPlayer.adEvent(currVideoId, currAdId, Math.round(time), type, {}));
    }
  };

  return {
    newAd,
    newProgram,
    sendAdHit,
    sendHit,
  };
};

export default gemius;
