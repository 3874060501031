import { DataLayerType, DataObjType } from '../../types/dataLayer';

declare let window: Window;

declare global {
  export interface Window {
    dataLayer: DataLayerType;
  }
}

interface PlayerEventType {
  eventName: string;
  interaction?: string;
  time: number;
}

interface PlayerSettingsEventType extends PlayerEventType {
  value: string | number | boolean;
}

interface PlayerAdStartType {
  adId: string;
  position: number;
  skipAfter?: number;
  slot: string;
  timeLength: number;
  videoId: string;
}
interface PlayerStartType {
  author?: string;
  autoplay: boolean;
  category?: string;
  contentType: 'live' | 'standard';
  entity?: string;
  fullscreen: boolean;
  mute: boolean;
  premium: 'locked' | 'unlocked';
  published?: string;
  tags?: string[];
  time: number;
  timeLength: number;
  title: string;
  videoId: string;
}

export interface DataLayerVideoServiceType {
  playerAdEvent: (props: PlayerEventType) => Promise<void>;
  playerAdStart: (props: PlayerAdStartType) => Promise<void>;
  playerEvent: (props: PlayerEventType) => Promise<void>;
  playerSettingsEvent: (props: PlayerSettingsEventType) => Promise<void>;
  playerStart: (props: PlayerStartType) => Promise<void>;
}

export interface DataLayerVideoType {
  app: string;
  templateLocation: string;
}

const dataLayerVideo = ({
  app,
  templateLocation,
}: DataLayerVideoType): DataLayerVideoServiceType => {
  let currVideoId: string;
  let currAdId: string;
  let videoLength: number;
  let adLength: number;

  // helpers function
  const getPlayerType = (): string => {
    return templateLocation === 'mini' ? 'videoMini' : 'videoNormal';
  };

  const pushToDataLayer = async (dataObj: DataObjType) => {
    if (window.dataLayer && window.didomiOnReady !== undefined) {
      await Promise.resolve(window.dataLayer.push(dataObj));
    }
  };

  const playerStart = ({
    author = '',
    autoplay,
    category,
    contentType,
    entity,
    fullscreen,
    mute,
    premium,
    published,
    tags,
    time,
    timeLength,
    title,
    videoId,
  }: PlayerStartType) => {
    currVideoId = videoId;
    videoLength = Math.floor(timeLength);

    return pushToDataLayer({
      _clear: true,
      event: 'player_start',
      player: {
        app,
        content: {
          author,
          category,
          entity,
          id: currVideoId,
          premium,
          published,
          tags,
          title,
          type: contentType,
        },
        settings: {
          autoplay,
          fullscreen,
          mute,
        },
        time: {
          length: videoLength,
          position: Math.round(time),
        },
        type: getPlayerType(),
        variant: 'standard', // if it will ever be possible to insert the player into the iframe, must be standard | embedded
      },
    });
  };

  const playerEvent = async ({ eventName, time, interaction = '' }: PlayerEventType) => {
    const dataObj: DataObjType = {
      event: `player_${eventName}`,
      player: {
        content: {
          id: currVideoId,
        },
        time: {
          length: videoLength,
          position: Math.round(time),
        },
      },
    };

    if (interaction !== '' && dataObj.player) {
      dataObj.player.interaction = interaction;
    }

    return pushToDataLayer(dataObj);
  };

  const playerSettingsEvent = async ({ eventName, time, value }: PlayerSettingsEventType) => {
    return pushToDataLayer({
      event: `player_settings_change_${eventName}`,
      player: {
        content: {
          id: currVideoId,
        },
        settings: {
          [eventName]: value,
        },
        time: {
          length: videoLength,
          position: Math.round(time),
        },
      },
    });
  };

  // ads events

  const playerAdStart = async ({
    adId,
    position,
    skipAfter,
    slot,
    timeLength,
  }: PlayerAdStartType) => {
    currAdId = adId;
    adLength = Math.round(timeLength);

    await pushToDataLayer({
      _clear: true,
      event: 'player_ads_loaded',
      player: {
        ads: {
          list: {
            id: currAdId,
            length: adLength,
            position,
            skipAfter,
            slot,
            type: 'video',
          },
        },
        content: {
          id: currVideoId,
        },
      },
    });
  };

  const playerAdEvent = async ({ eventName, time }: PlayerEventType) => {
    return pushToDataLayer({
      event: `player_${eventName}`,
      player: {
        ads: {
          currentAd: {
            id: currAdId,
          },
        },
        content: {
          id: currVideoId,
        },
        time: {
          length: adLength,
          position: Math.round(time),
        },
      },
    });
  };

  return {
    playerAdEvent,
    playerAdStart,
    playerEvent,
    playerSettingsEvent,
    playerStart,
  };
};

export default dataLayerVideo;
